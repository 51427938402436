import { Route, Switch } from 'react-router-dom'
import Dashboard from './Dashboard'
import Login from './Login'
import Signup from './Signup'

export default function Routes() {
  return (
    <Switch>
      <Route exact path='/'>
        <Dashboard/>
      </Route>
      <Route exact path='/login'>
        <Login/>
      </Route>
      <Route exact path='/signup'>
        <Signup/>
      </Route>
    </Switch>
  )
}
