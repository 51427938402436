import { BrowserRouter as Router, Link } from 'react-router-dom';
import { Amplify } from 'aws-amplify'

import './App.css';
import Routes from './Routes';
// import config from '../configs/amplify';

function App() {

  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: 'ap-southeast-2',
      userPoolId: 'ap-southeast-2_sXJhHNpYf',
      identityPoolId: 'authstackIdentityPool8FA66870',
      userPoolWebClientId: 'o6ie4ad3cc1612ueg6jh84v5'
    }
  })

  return (
    <Router>
      <header>
        <nav>
          <ul>
            <li>
              <Link to='/'>Home</Link>
            </li>
            <li>
              <Link to='/login'>Login</Link>
            </li>
            <li>
              <Link to='/signup'>signup</Link>
            </li>
          </ul>
        </nav>
      </header>
      <div className="container">
        <Routes />
      </div>
    </Router>
  );
}

export default App;
