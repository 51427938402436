import { useState } from 'react'
import { Auth } from 'aws-amplify'

import './Login.css'

export default function Login() {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    console.log({username, password})
    const res = await Auth.signIn({username, password}).catch(console.error)
    // const res = await Auth.signUp({ username: 'another', password, attributes: { email: username } }).catch(console.error)
    // const res = await Auth.confirmSignUp('another', '569924').catch(console.error)
    console.log(res)
  }
  return (
    <div className="login">
      <form action="#" onSubmit={handleSubmit}>
        <div className="formgroup">
          <label htmlFor="username">username</label>
          <input type="text" id='username' onChange={(e) => setUsername(e.target.value)}/>
        </div>
        <div className="formgroup">
          <label htmlFor="password">password</label>
          <input type="password" id='password' onChange={(e) => setPassword(e.target.value)}/>
        </div>
        <input type="submit" value='Login'/>
      </form>
    </div>
  )
}